import React from "react"
import styled from "styled-components"

import imageBg from "../../images/login_daftar/backgroundBackCard.png"

const CardWrapperStyles = styled.div`
  min-height: 600px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${imageBg}) no-repeat center center;
  background-size: 1430px;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 120px;

  .card-wrapper-all {
    max-width: 500px;
    width: 95%;
  }

  .is-login-wrap {
    padding-top: 100px;
  }

  .white-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: auto;
    padding: 20px;
  }

  .input-wrapper {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .button-wrapper {
    text-align: center;
    padding-top: 10px;

    button {
      width: 100%;
    }
  }

  @media only screen and (max-height: 750px) {
    min-height: 500px;

    .is-login-wrap {
      padding-top: 5vh;
    }
  }
`

export default function CardWrapper({ children, current }) {
  return (
    <CardWrapperStyles>
      <div
        className={`card-wrapper-all ${
          current === "login" ? "is-login-wrap" : ""
        }`}
      >
        <div className="white-wrapper">{children}</div>
      </div>
    </CardWrapperStyles>
  )
}
